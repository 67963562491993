<template>
  <ul
    v-show="isOpen"
    ref="listRef"
    :aria-label="label"
    role="listbox"
    tabindex="-1"
    class="menu--container"
    :aria-activedescendant="activeDescendantId"
    @keydown.prevent="handleKeyDown"
    @focus="handleInitialFocus"
    @click="emitClose"
  >
    <li
      v-for="option in options"
      :id="getOptionId(option)"
      :key="option.value"
      ref="optionRef"
      role="option"
      :class="[
        'menu--list-item',
        { 'menu--list-item--focus': option === activeOption }
      ]"
      :type="type"
      :aria-disabled="isOptionDisabled(option)"
      :aria-selected="isOptionSelected(option)"
      data-testid="menu-list-item"
      @click="selectOption(option)"
    >
      <span v-if="!isMultiselect" class="text">{{ option.label }}</span>
      <span
        v-if="isOptionSelected(option) && !isMultiselect"
        class="icon"
        aria-hidden="true"
      >
        <BaseIcon width="24" height="24" :icon="icon" />
      </span>
      <Checkbox
        v-if="isMultiselect"
        :id="getOptionId(option)"
        v-model="inputValue"
        :value="option.value"
        class="checkbox"
        reverse
      >
        {{ option.label }}
      </Checkbox>
    </li>
    <div v-if="isMultiselect" class="menu--container--button">
      <ButtonPrimary
        :label="buttonLabel"
        class="menu--button"
        @click="menuButtonEvent"
        v-on="$listeners"
      />
    </div>
  </ul>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Ref, Watch } from 'vue-property-decorator';
import BaseIcon from '../base-icon/BaseIcon.vue';
import { IMenu, MenuValue, MenuType, IMenuMultipleValue } from './types';
import { KeyCode } from '../../typings/KeyCode';
import { Checkbox } from '@/components/checkbox';
import { ButtonPrimary } from '@/components/button';

@Component({
  name: 'Menu',
  model: {
    prop: 'value',
    event: 'selected'
  },
  components: { BaseIcon, Checkbox, ButtonPrimary }
})
export default class Menu extends Vue {
  /**
   * O rótulo do Menu
   * A label não é exibida, mas é adicionada por questão de acessibilidade
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * O rótulo do Botão quando existe
   */
  @Prop({ type: String })
  readonly buttonLabel?: string;

  /**
   * Value do Menu
   */
  @Prop({ type: [String, Number, Array, Object] })
  readonly value!: MenuValue;

  @Ref('optionRef')
  readonly optionRefs!: HTMLElement[];

  @Ref('listRef')
  readonly listRefs!: HTMLElement;

  /** o ícone a ser exibido na opção selecionada quando single */
  @Prop({ type: String, default: 'EF0070' })
  readonly icon?: string;

  /** Para desativar o Menu */
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  /**
   * Indica se a menu está aberta e deve ser renderizada
   */
  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  /**
   * Tipo de seleção
   * @values single, multiple
   * */
  @Prop({
    type: String,
    default: MenuType.Single,
    validator: (propValue: MenuType) => {
      return Object.values(MenuType).includes(propValue);
    }
  })
  readonly type!: MenuType;

  /**
   * Opções do Menu
   */
  @Prop({ type: Array, required: true })
  readonly options!: IMenu[];

  /**
   * Cor do Caption a ser exibido
   */
  @Prop({ type: String })
  readonly captionColor?: string;

  /**
   * Opção do menu que está sendo navegada por teclado
   */
  private activeOption: IMenu | null = null;

  /**
   * Evento de click do botão, quando existente
   */
  @Emit('click-button')
  public menuButtonEvent() {
    // Função que emite evento
  }

  /**
   * Evento que escuta quando o menu é aberto e seta o focus
   */
  @Watch('isOpen')
  isOpenWatch(newValue: boolean) {
    if (newValue) {
      this.setActiveOption();
      this.$nextTick(() => {
        this.listRefs.focus();
      });
    }
  }

  /**
   * Evento emitido quando o focus é removido do Menu
   */
  @Emit('close')
  private emitClose() {
    // Função que emite evento close
  }

  /**
   * Evento que retorna o valor atual e o captionColor do Menu a cada click. O captionColor não é obrigatório, podendo retornar undefined, nisso retornamos apenas o value
   */
  @Emit('selected')
  private emitClick(
    value: MenuValue,
    captionColor: string | undefined = undefined
  ) {
    if (captionColor === undefined) {
      return value;
    }
    return { value, captionColor };
  }

  get isMultiselect() {
    return this.type === MenuType.Multiple;
  }

  set inputValue(value: MenuValue) {
    this.emitClick(value);
  }

  get inputValue() {
    return this.value;
  }

  setActiveOption() {
    this.activeOption =
      this.options.find(option => option.value === this.value) || null;
  }

  isOptionSelected(option: IMenu) {
    const value = this.value as IMenuMultipleValue;
    if (this.isMultiselect) {
      return Array.isArray(value.values)
        ? (value.values as unknown as IMenuMultipleValue).includes(option.value)
        : this.value;
    } else {
      return this.value === option.value;
    }
  }

  isOptionDisabled(option: IMenu) {
    return this.disabled || option.disabled;
  }

  getOptionId(option: IMenu) {
    const value = typeof option === 'object' ? option.value : option;
    return `menu-option-${value}`;
  }

  get activeDescendantId() {
    const active = this.activeOption || (this.value as IMenu);
    return active ? this.getOptionId(active) : null;
  }

  private get currentActiveIndex() {
    return this.options.findIndex(option => {
      return this.getOptionId(option) === this.activeDescendantId;
    });
  }

  handleKeyDown(event: KeyboardEvent, startIndex?: number) {
    const lastOptionIndex = this.options.length - 1;
    let activeIndex =
      startIndex !== undefined ? startIndex : this.currentActiveIndex;

    switch (event.key) {
      case KeyCode.ArrowDown:
        // se estamos navegando pelo último item devemos circular na lista até o topo
        if (activeIndex === lastOptionIndex) {
          activeIndex = 0;
          break;
        }
        activeIndex += 1;
        break;
      case KeyCode.ArrowUp:
        // se estamos navegando pelo primeiro item devemos circular na lista até a última opção
        if (activeIndex === 0) {
          activeIndex = lastOptionIndex;
          break;
        }
        activeIndex -= 1;
        break;
      case KeyCode.Home:
        activeIndex = 0;
        break;
      case KeyCode.End:
        activeIndex = lastOptionIndex;
        break;
      case KeyCode.Enter:
      case KeyCode.SpaceBar:
        if (!this.options[activeIndex]) return;
        this.selectOption(this.options[activeIndex]);

        this.emitClose();

        break;
      case KeyCode.Escape:
      case KeyCode.Tab:
        this.emitClose();
        return;
      default:
        return;
    }
    const nextActive = this.options[activeIndex];

    if (!nextActive) return;

    const isNextActiveDisabled = nextActive.disabled;

    // Pula essa opção se ela está desabilitada, reaproveitamos o mesmo keycode do evento
    if (isNextActiveDisabled) {
      this.handleKeyDown(event, activeIndex);
    } else {
      this.focusOption(activeIndex);
    }
  }

  handleInitialFocus() {
    this.focusOption(this.currentActiveIndex);
  }

  selectOption(option: IMenu) {
    if (this.isOptionDisabled(option) || this.isOptionSelected(option)) {
      return;
    }

    this.activeOption = option;
    this.emitClick(option.value, option.captionColor);
  }

  private focusOption(index: number) {
    this.activeOption = this.options[index];
    const activeElement = this.optionRefs[index];
    if (activeElement) {
      // Faz scroll até o elemento caso a lista de opções tenha scroll vertical
      activeElement.scrollIntoView({ block: 'nearest' });
    }
  }
}
</script>

<style lang="less" scoped>
.menu--container {
  outline: none;
  list-style: none;
  background: @background-secondary;
  box-sizing: border-box;
  border: @size-border-x400 solid @divider-primary;
  border-radius: @size-radius-x100;
  box-shadow: 0px 18px 40px rgba(47, 47, 51, 0.14);
  padding: 0;
  margin: 0;
  min-width: 240px;

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: @size-border-x500 solid @element-primary !important;
  }
}

.menu--list-item {
  .text-p-4();
  color: @element-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  margin: 0;
  padding: @size-spacing-x350 @size-spacing-x400 @size-spacing-x350
    @size-spacing-x400;
  cursor: pointer;

  &--focus,
  &:hover {
    background-color: @background-hover;
  }

  &[aria-disabled='true'] {
    color: @element-disabled !important;
    pointer-events: none;
  }
  &:first-of-type .menu--item {
    padding: @size-spacing-x400 @size-spacing-x400 @size-spacing-x350
      @size-spacing-x400;
  }
  &:last-child .menu--item {
    padding: @size-spacing-x350 @size-spacing-x400 @size-spacing-x400
      @size-spacing-x400;
  }
}

.icon {
  padding-left: @size-spacing-x500;
  line-height: 0;
}

.checkbox {
  height: 26px;
}

.menu--container--button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: @size-border-x400 solid @divider-primary;
  padding: @size-spacing-x400;
}

.menu--button {
  width: 206px;
}

::v-deep .checkbox.container {
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
</style>
