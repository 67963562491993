<template>
  <input
    ref="inputRange"
    :value="value"
    data-testid="input-range"
    :min="min"
    :max="max"
    :step="step"
    :style="{ '--range-color': color, '--range-percentage': percentage }"
    type="range"
    class="input-range"
    @input="updateValue"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'InputRange'
})
export default class InputRange extends Vue {
  /**
   * Valor inicial do input
   */
  @Prop({ type: Number, default: 0, required: false })
  private value!: number;

  /**
   * Indica o valor mínimo do input
   */
  @Prop({ type: Number, required: false, default: 0 })
  private min!: number;

  /**
   * Indica o valor máximo do input
   */
  @Prop({ type: Number, required: false, default: 100 })
  private max!: number;

  /**
   * Indica o intervalo entre os números
   */
  @Prop({ type: Number, required: false, default: 1 })
  private step!: number;

  /**
   * Define a cor do input
   */
  @Prop({
    type: String,
    required: false,
    default: NebraskaColors.elementPrimary
  })
  private color!: NebraskaColors;

  /**
   * Retorna o valor atual do input, a cada mudança
   */
  @Emit('input')
  updateValue(event: Event): number {
    return (event.target as HTMLInputElement).valueAsNumber;
  }

  @Watch('value')
  onValueChanged() {
    // Não precisamos executar nenhuma lógica, pois a porcentagem é recomputada
    // automaticamente e o estilo é aplicado diretamente no template.
  }

  public get percentage() {
    const value = this.value;
    const min = this.min || 0;
    const max = this.max || 100;
    const percentage = ((value - min) / (max - min)) * 100;

    return `${percentage}%`;
  }
}
</script>

<style lang="less" scoped>
.input-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: @background-quartenary;
  border-radius: @size-radius-x100;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--range-color);
    border-radius: @size-radius-x100;
    width: var(--range-percentage);
    z-index: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: @size-radius-max;
    background: var(--range-color);
    position: relative;
    z-index: 2;
  }

  &::-moz-range-track {
    background: @background-quartenary;
    border-radius: @size-radius-x100;
  }

  &::-moz-range-progress {
    background: var(--range-color);
    border-radius: @size-radius-x100;
    height: 8px;
  }
}
</style>
